import Config from "../config";

export const getParams = () => {
    var queryParams = window.location.search
        .substr(1)
        .split("&")
        .reduce(function (q, query) {
            var chunks = query.split("=");
            var key = chunks[0];
            var value = chunks[1];
            return (q[key] = value) && q;
        }, {});

    return queryParams;
};

export const showLeadFeature = (companyId) => {
    if (Config.ENVIRONMENT === 'local' || Config.ENVIRONMENT === 'development') {
        if (companyId === 17 || companyId === 1 || companyId === 16) {
            return true;
        }
    } else {
        if (companyId === 2 || companyId === 72 || companyId === 29) {
            return true;
        }
    }
    return false;
}